import { ControlType } from 'braft-editor';
import JSEncrypt from 'jsencrypt';

import { serviceApi } from '@/configs/default';
import { serverUploadApi } from '@/configs/default';

export function UploadFn(param: any) {
  const serverURL = serverUploadApi;
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  const successFn = () => {
    // 上传成功后调用param.success并传入上传后的文件地址
    const res = JSON.parse(xhr.responseText);
    param.success({
      url: res.result.fullOssUrl,
      meta: {
        id: res.result.id,
        title: res.result.fileName,
        alt: res.result.fileName,
        loop: true, // 指定音视频是否循环播放
        autoPlay: true, // 指定音视频是否自动播放
        controls: true, // 指定音视频是否显示控制栏
        // poster: 'http://xxx/xx.png', // 指定视频播放器的封面
      },
    });
  };

  const progressFn = (event: any) => {
    // 上传进度发生变化时调用param.progress
    param.progress((event.loaded / event.total) * 100);
  };

  const errorFn = () => {
    // 上传发生错误时调用param.error
    param.error({
      msg: '上传有误，请稍后再试!',
    });
  };
  xhr.upload.addEventListener('progress', progressFn, false);
  xhr.addEventListener('load', successFn, false);
  xhr.addEventListener('error', errorFn, false);
  xhr.addEventListener('abort', errorFn, false);
  fd.append('file', param.file);
  xhr.open('POST', serverURL, true);
  xhr.send(fd);
}

export const uploadConfig: ControlType[] = [
  'blockquote',
  'bold',
  'clear',
  'emoji',
  'font-family',
  'font-size',
  'fullscreen',
  'headings',
  'hr',
  'italic',
  'letter-spacing',
  'line-height',
  'link',
  'list-ol',
  'list-ul',
  'media',
  'redo',
  'remove-styles',
  'separator',
  'strike-through',
  'superscript',
  'subscript',
  'text-align',
  'text-color',
  'text-indent',
  'underline',
  'undo',
];

export const uploadAccept = {
  image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg',
  video: 'video/mp4',
  audio: 'audio/mp3',
};

/**
 * @description: 替换img标签
 * @param {String} dataStr 传入的要替换的字符串
 * @return {String} 替换完成的字符串
 */
export const replaceImgSrc = async (dataStr: string) => dataStr.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, '');

export const clearInformation = async () => {
  localStorage.removeItem('authorization');
  localStorage.removeItem('type');
  localStorage.removeItem('vip');
  localStorage.removeItem('username');
  localStorage.removeItem('phone');
  localStorage.removeItem('isOccHealth');
  localStorage.removeItem('certificateNo');
  localStorage.removeItem('userType');
  localStorage.removeItem('email');
  localStorage.removeItem('unitName');
  localStorage.removeItem('expert');
  localStorage.removeItem('institution');
  localStorage.removeItem('talent');
  localStorage.removeItem('IfShowAssessReEvaluate');
  localStorage.removeItem('permissions');
  localStorage.removeItem('proposerName');
  localStorage.removeItem('partner');
  localStorage.removeItem('serviceTypes');
  localStorage.removeItem('userImg');
  window.location.href = '/login';
};

/**
 * @description: 时间字符串处理'YYYY-MM-DD HH:mm:ss' -> 'YYYY-MM-DD HH:mm' / 'YYYY-MM-DD'
 * @param {String} dataStr 传入的要替换的字符串
 * @return {String} 替换完成的字符串
 */
export const dealTimeStr = (dataStr: string) => {
  let result = dataStr;
  if (dataStr && dataStr.length === 19) {
    const tmpLastStr = dataStr.slice(11);
    if (tmpLastStr === '00:00:00') {
      result = dataStr.slice(0, 10);
    }
  }
  return result;
};

/**
 * @description RSA非对称加密
 * @param data 需要加密的数据
 * @param PUBLIC_KEY 公钥
 */
export function rsaEncrypt(data: string, PUBLIC_KEY: string) {
  // 使用公钥加密
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const result = encrypt.encrypt(data);
  return result;
};

/**
 * 登录失效/退出登录处理跳转
 * @author fxz
 */
export const dealLoginOutJump = () => {
  if (localStorage.getItem('isService')) {
    window.location.href = `${serviceApi}/login`;
  } else {
    window.location.href = '/login';
  }
};
