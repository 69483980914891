import { observer } from 'mobx-react-lite';
import './index.less';

import { AgreementSvg, OperationSvg } from '@/components/iconfonts';
import { useStores } from '@/store';
import {
  Col, Divider, Dropdown, Menu, MenuProps, Row,
} from 'antd';

/**
 * 前台页面-页脚
 */
export default observer(() => {
  const stores: any = useStores();
  const { serverHomeStore } = stores;
  const { clickNum } = serverHomeStore;

  /**
   * @description: 下拉菜单点击事件
   * @param {*} param 具体见Antd
   * @return {*}
   */
  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'serviceUser') {
      return;
    }
    if (key === 'user') {
      window.location.href = require('../../../assets/templates/operation-user.docx').default;
    }
    if (key === 'unit') {
      window.location.href = require('../../../assets/templates/operation-unit.docx').default;
    }
  };

  // 下拉菜单
  const menu = (
    <Menu
      onClick={onClick}
    >
      {localStorage.getItem('isService') ? (
        <Menu.Item key="serviceUser" title="下载个人用户操作手册">
          <a href="/operationGuide/【20241217】中国职业健康服务平台培训操作手册.docx" download>个人用户操作手册</a>
        </Menu.Item>
      ) : (
        <>
          <Menu.Item key="user" title="下载个人用户操作手册">个人用户操作手册</Menu.Item>
          <Menu.Item key="unit" title="下载单位用户操作手册">单位用户操作手册</Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <div className="server-layout-footer">
      <div className="server-container">
        <div className="item">
          <span>
            {/* <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602005885" target="_blank" rel="noreferrer"> 浙公网安备33010602005885号</a> */}
          </span>
          <Row gutter={24}>
            <Col>
              <Dropdown overlay={menu} arrow>
                <a href="/" className="flex-box" onClick={(e:any) => e.preventDefault()}>
                  <span className="icon">
                    <OperationSvg style={{ fontSize: '16px', color: '#3662EB' }} />
                  </span>
                  <span className="content">操作手册</span>
                </a>
              </Dropdown>
            </Col>
            {/* <Col className="flex-box">
              <span className="icon">
                <AgreementSvg style={{ fontSize: '16px', color: '#3662EB' }} />
              </span>
              <a href="/other/agreement" className="content" target="_blank">服务协议</a>
              <Divider type="vertical" />
              <a href="/other/privacy" className="content" target="_blank">隐私政策</a>
            </Col> */}
            {/* <Col className="flex-box">
              <span className="icon">
                <CursorSvg style={{ fontSize: '16px', color: '#3662EB' }} />
              </span>
              <span className="content">
                网页点击量：
                {clickNum}
              </span>
            </Col> */}
          </Row>
        </div>
        {/* <div className="item">
          <Row gutter={24}>
            <Col>
              增值电信业务经营许可证：浙B2-20170059
            </Col>
            <Col>
              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">
                浙ICP备16005120号-2
              </a>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>技术支持：智慧科技</Col>
            <Col>联系方式：0571-85166777</Col>
          </Row>
        </div> */}
      </div>
    </div>
  );
});
