import { ReactNode, lazy } from 'react';

// 培训管理
const trainingList = lazy(() => import('@/adminPages/trainingManagement/list'));
const trainingAdd = lazy(() => import('@/adminPages/trainingManagement/add'));
const trainingDetail = lazy(() => import('@/adminPages/trainingManagement/detail'));

// 课程组管理
const courseGroupManagement = lazy(() => import('@/adminPages/courseManagement'));
const courseManagement = lazy(() => import('@/adminPages/courseManagement/detail'));
// 试题管理
const questionList = lazy(() => import('@/adminPages/questionManagement/list'));
const structureManagement = lazy(() => import('@/adminPages/questionManagement/list/structureManagement'));

// 证书管理
const certificateManagement = lazy(() => import('@/adminPages/certificateManagement'));

// 订单管理
const orderManagement = lazy(() => import('@/adminPages/orderManagement'));
const orderDetail = lazy(() => import('@/adminPages/orderManagement/detail'));

// 系统管理-用户管理
const mangerUser = lazy(() => import('@/adminPages/systemManagement/managerUser'));
const mangerUserPersonDetail = lazy(() => import('@/adminPages/systemManagement/managerUser/user/detail/info'));
const mangerUserPersonDetailTrain = lazy(() => import('@/adminPages/systemManagement/managerUser/user/detail/train'));
const mangerUserUnitDetail = lazy(() => import('@/adminPages/systemManagement/managerUser/unit/detail/info'));
const mangerUserUnitDetailTrain = lazy(() => import('@/adminPages/systemManagement/managerUser/unit/detail/train'));
// 系统管理-用人单位底库
const employerManagement = lazy(() => import('@/adminPages/employerManagement'));
// 系统管理-系统日志
const systemLog = lazy(() => import('@/adminPages/systemLog'));
// 系统管理-数据字典
const dataDictionary = lazy(() => import('@/adminPages/dataDictionary'));
const childLevel = lazy(() => import('@/adminPages/dataDictionary/info'));
// 系统管理-参数管理
const parameterManagement = lazy(() => import('@/adminPages/parameterManagement'));

// 系统管理-角色管理
const managerRole = lazy(() => import('@/adminPages/systemManagement/managerRole/list'));

// const DataStatistics = lazy(() => import('@/adminPages/dataStatistics')); // 数据统计

const AdminInfoMation = lazy(() => import('@/adminPages/adminInformation')); // 资讯动态管理
const AddAdminInfomation = lazy(() => import('@/adminPages/adminInformation/addinfo')); // 新增资讯动态管理
const WatchAdminInfomation = lazy(() => import('@/adminPages/adminInformation/watch')); // 查看资讯信息

const MessageManagement = lazy(() => import('@/adminPages/messageManagement')); // 联系我们管理
const AdminPreview = lazy(() => import('@/adminPages/messageManagement/preview')); // 联系我们预览

// 考试须知
const adminExamInstructions = lazy(() => import('@/adminPages/adminExamInstructions'));

interface RouteConfig {
  path: string,
  key: string,
  component: ReactNode,
  redirect?: string,
  children?: RouteConfig[],
}

const routesConfig: RouteConfig[] = [
  {
    path: '/admin',
    key: '后台',
    component: () => <div>后台首页</div>,
    redirect: '/admin/trainingManagement',
    children: [
      {
        path: '/home',
        key: '后台首页',
        redirect: '/trainingManagement',
        component: <div>后台首页</div>,
      },
      {
        path: '/trainingManagement',
        key: '培训管理',
        redirect: '/trainingManagement/list',
        component: () => <div>培训管理</div>,
        children: [
          {
            path: '/list',
            key: '培训列表',
            component: trainingList,
          },
          {
            path: '/add',
            key: '培训新增',
            component: trainingAdd,
          },
          {
            path: '/edit/:id',
            key: '培训编辑',
            component: trainingAdd,
          },
          {
            path: '/detail/:id',
            key: '培训查看',
            component: trainingDetail,
          },
        ],
      },
      {
        path: '/system',
        key: '系统管理',
        redirect: '/system/managerUser',
        component: () => <div>系统管理</div>,
        children: [
          {
            path: '/managerUser',
            key: '用户管理',
            component: mangerUser,
            children: [
              {
                path: '/userDetail/:id',
                key: '个人用户详情',
                component: mangerUserPersonDetail,
                children: [
                  {
                    path: '/train/:trainId/:tuEnrollDetailId',
                    key: '个人用户培训详情',
                    component: mangerUserPersonDetailTrain,
                  },
                ],
              },
              {
                path: '/unitDetail/:id',
                key: '单位用户详情',
                component: mangerUserUnitDetail,
                children: [
                  {
                    path: '/train/:trainId',
                    key: '单位用户培训详情',
                    component: mangerUserUnitDetailTrain,
                  },
                ],
              },
            ],
          },
          {
            path: '/managerRole',
            key: '角色管理',
            component: managerRole,
          },
          {
            path: '/managerUnit',
            key: '用人单位底库',
            component: employerManagement,
          },
          {
            path: '/managerLog',
            key: '系统日志',
            component: systemLog,
          },
          {
            path: '/managerDictionary',
            key: '数据字典',
            component: dataDictionary,
            children: [
              {
                path: '/managerWork/:id/:name',
                key: '适用工作类别管理',
                component: dataDictionary,
                children: [
                  {
                    path: '/childLevel/:id',
                    key: '职业照射代码',
                    component: childLevel,
                  },
                ],
              },
            ],
          },
          {
            path: '/managerParam',
            key: '参数管理',
            component: parameterManagement,
          },
        ],
      },
      {
        path: '/courseManagement',
        key: '课程组管理',
        redirect: '/courseManagement/group',
        component: () => <div>课程组管理</div>,
        children: [
          {
            path: '/group',
            key: '课程组列表',
            component: courseGroupManagement,
          },
          {
            path: '/list',
            key: '课程列表',
            component: courseManagement,
          },
        ],
      },
      {
        path: '/question',
        key: '试题管理',
        redirect: '/question/list',
        component: () => <div>试题管理</div>,
        children: [
          {
            path: '/list',
            key: '试题管理列表',
            component: questionList,
            children: [
              {
                path: '/structureManagement',
                key: '试卷结构列表',
                component: structureManagement,

              },

            ],
          },
        ],
      },
      {
        path: '/certificate',
        key: '证书管理',
        component: certificateManagement,
      },
      {
        path: '/order',
        key: '订单管理',
        component: <div>订单管理</div>,
        redirect: '/order/list',
        children: [
          {
            path: '/list',
            key: '订单管理列表',
            component: orderManagement,
          },
          {
            path: '/watch/:id',
            key: '查看订单详情',
            component: orderDetail,
          },
        ],
      },
      {
        path: '/newsInfo',
        key: '资讯动态管理',
        component: AdminInfoMation,
        children: [
          {
            path: '/addinfo',
            key: '资讯动态信息',
            component: AddAdminInfomation,
          },
          {
            path: '/watch',
            key: '查看资讯动态',
            component: WatchAdminInfomation,
          },
        ],
      },
      {
        path: '/contact',
        // key: '关于我们管理',
        key: '',
        component: MessageManagement,
      },
      {
        path: '/examInstructions',
        key: '考试须知管理',
        component: adminExamInstructions,
      },
    ],
  },
  {
    path: '/previewWatch',
    component: AdminPreview,
    key: '后台关于我们预览',
  },
];

export default routesConfig;
