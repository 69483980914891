/* eslint-disable no-param-reassign */
import { serviceApi } from '@/configs/default';
import { message } from 'antd';
import axios, { Method } from 'axios';
// import { apiPath } from '@/configs/default';

axios.defaults.withCredentials = true; // 允许跨域携带cookie信息
// 拦截器请求设置
axios.interceptors.request.use(
  (config: any) => {
    config.headers = {
      token: localStorage.getItem('token'),
      ...config.headers,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

// 拦截器响应设置
axios.interceptors.response.use(
  (response) => {
    if (response.headers.token) {
      localStorage.setItem('token', response.headers.token);
    }
    if (response?.data?.state?.value === 401) { // 登录失效
      // axios.post('/v2/www/login/token/refresh').then((res) => {
      //   console.log(res);
      // }).catch(() => {});
      message.destroy();
      message.warning(response?.data?.message).then(() => {
        // 保留个人中心userType判断
        const userType:any = localStorage.getItem('userType');
        // 服务平台账号判断
        const isService = localStorage.getItem('isService');
        localStorage.clear();
        localStorage.setItem('userType', userType);
        if (isService) { // 服务平台单点登录
          // 额外添加账号来源（服务平台）
          localStorage.setItem('isService', 'true');
          window.location.href = `${serviceApi}/login`;
        } else {
          window.location.href = '/login';
        }
      });
    } else if (response?.data?.state?.value === 403) {
      message.warning(response?.data?.message || '暂无访问权限');
    }
    return response;
  },
  (error) => {
    message.error('请求出错');
    return Promise.reject(error);
  }, // 接口500抛出异常（不走页面逻辑）
);

const Ajax = (
  baseurl = '',
  params = {},
  type: Method | 'POST_FORM' = 'GET',
  config?: {},
  returnAll = false,
) => {
  let promise;

  // const path = process.env.NODE_ENV === 'development' ? apiPath.development : apiPath.production;
  const url = baseurl;

  return new Promise((resolve, reject) => {
    if (type.toUpperCase() === 'POST_FORM') {
      promise = axios(url, { method: 'POST', params, ...config });
    } else if (type.toUpperCase() === 'GET') {
      promise = axios.get(url, {
        ...config,
        params,
      });
    } else {
      promise = axios(url, {
        method: type as Method,
        data: params,
        ...config,
      });
    }

    promise.then((res: any) => {
      if (res.data) {
        resolve(returnAll ? res : res.data);
      } else {
        resolve(false);
      }
    }).catch((err: any) => {
      reject(err);
    });
  });
};

export default Ajax;
