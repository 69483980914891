import {
  useState,
  useEffect,
} from 'react';
import {
  Layout,
  Menu,
  Button,
  Dropdown,
} from 'antd';
import {
  MenuOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useStores } from '@/store';
import { observer } from 'mobx-react-lite';

import './index.less';
import leftMentConfig from '@/layout/components/adminLeftMenu/config';

import BreadCrumb from '@/layout/components/adminBreadCrumb';
import LeftMenu from '@/layout/components/adminLeftMenu';
import Footer from '@/layout/components/adminFooter';

import { dealLoginOutJump } from '@/tools/tool';

import ResetPassWord from '../components/resetPassWord';

/**
 * 后台页面-基本结构
 */
export default observer((props: any) => {
  const stores: any = useStores();
  const { loginStore, UserInfoStore, serverHomeStore } = stores;
  const { signOut } = loginStore;
  const { userRoleType, userPermission } = UserInfoStore;
  const { webTitle } = serverHomeStore;
  const { Header, Content, Sider } = Layout;
  const history: any = useHistory();
  const location: any = useLocation();

  /**
   * @constant sideClose 是否关闭侧边栏
   * @constant mEditPass 修改密码弹框显示
   * @constant defaultOpenkeys 左侧菜单初始展开项key
   * @constant defaultSelectKeys 左侧菜单初始选中key
   */
  const [sideClose, setSideClose] = useState(false);
  const [mEditPass, setMEditPass] = useState(false);
  const [defaultOpenkeys, setDefaultOpenKeys] = useState<string[]>([]);
  const [defaultSelectKeys, setDefaultSelectKeys] = useState<string[]>([]);

  /**
   * 函数存放对象
   */
  const fns = {
    /**
     * 展开左侧菜单定义数组
     */
    flatArr(arr: any[]) {
      const resArr: any[] = [];
      arr.forEach((item: any) => {
        resArr.push({ key: item.path, isOpen: item.children ? 1 : 0 });
        if (item.children) {
          resArr.push(...fns.flatArr(item.children));
        }
      });
      return resArr;
    },
  };

  /**
   * 操作处理函数存放对象
   */
  const handles = {
    /**
     * 前台首页按钮处理事件
     */
    goServerHome() {
      window.open('/');
    },

    /**
     * 侧边栏切换
     */
    sideMenuSwitch() {
      setSideClose((previousValue: boolean) => !previousValue);
    },

    /**
     * 退出系统
     */
    signOut() {
      signOut({}, () => { dealLoginOutJump(); });
    },

    /**
     * 修改密码
     */
    editPass() {
      setMEditPass(true);
    },
  };

  /**
   * 下拉菜单
   */
  const menu = (
    <Menu>
      <Menu.Item
        key="editPass"
        onClick={handles.editPass}
      >
        修改密码
      </Menu.Item>
      <Menu.Item
        key="register"
        onClick={handles.signOut}
      >
        退出系统
      </Menu.Item>
    </Menu>
  );

  /**
   * 路由变化设置左侧菜单
   */
  useEffect(() => {
    if (location.pathname.includes('/admin/home')) {
      if (!userPermission.includes('home:view')) {
        const perList = [
          {
            per: 'training:view',
            key: '培训管理',
            path: '/admin/trainingManagement/list',
          },
          {
            per: 'training_course:view',
            key: '课程组管理',
            path: '/admin/courseManagement/group',
          },
          {
            per: 'question:item:view',
            key: '试题管理列表',
            path: '/admin/question/list',
          },
          {
            per: 'question:structure:view',
            key: '试题管理试卷结构',
            path: '/admin/question/list/structureManagement',
          },
          {
            per: 'cert:view',
            key: '证书管理',
            path: '/admin/certificate',
          },
          {
            per: 'sys:user:view',
            key: '用户管理',
            path: '/admin/system/managerUser',
          },
          {
            per: 'sys:role:view',
            key: '角色管理',
            path: '/admin/system/managerRole',
          },
          {
            per: 'sys:view',
            key: '系统管理',
            path: '/admin/system/managerParam',
          },
          {
            per: 'web:news:view',
            key: '资讯动态管理',
            path: '/admin/newsInfo',
          },
          {
            per: 'web:about:view',
            key: '关于我们管理',
            path: '/admin/contact',
          },
          {
            per: 'paper_instructions:view',
            key: '考试须知管理',
            path: '/admin/examInstructions',
          },
        ];
        const mathItem = perList.find((item) => userPermission.includes(item.per));
        if (mathItem) {
          history.push(mathItem.path);
        } else {
          history.push('/admin/noPer');
        }
      }
    }

    const routes = fns.flatArr(leftMentConfig);
    const defaultOpen: string[] = [];
    const defaultSelect: string[] = [];

    routes.forEach((item: any) => {
      if (location.pathname.includes(item.key)) {
        if (item.isOpen) {
          defaultOpen.push(item.key);
        } else {
          defaultSelect.push(item.key);
        }
      }
    });

    setDefaultOpenKeys(defaultOpen);
    setDefaultSelectKeys(defaultSelect);
  }, [location.pathname]);

  /**
   * 头部初始化定位
   */
  useEffect(() => {
    function handleResize() {
      const width = Math.max(document.body.offsetWidth, document.documentElement.offsetWidth);
      const hasScroll = document.documentElement.scrollHeight > document.documentElement.offsetHeight; // 是否有滚动条
      const maxWidth = 1440 - (hasScroll ? 17 : 0);
      if (width < maxWidth) {
        setSideClose(true);
      } else {
        setSideClose(false);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize, false);
  }, []);

  /**
   * 初始化
   */
  useEffect(() => {
    if (userRoleType !== 3) {
      window.location.href = '/login';
    }
  }, []);

  return (
    <div className="admin-layout">
      <Layout style={{ minHeight: '100vh' }}>
        {/* 头部 */}
        <Header>
          <div className="admin-layout-header">
            <div className="logo">{webTitle}</div>
            <div className="header-right">
              <div className="header-btns">
                <Button type="text" className="primary-text-btn" onClick={handles.goServerHome}>前台首页</Button>
              </div>
              <div className="header-user">
                <Dropdown
                  overlay={menu}
                  overlayClassName="dropdown-box"
                >
                  <div className="item">
                    用户:&nbsp;
                    {localStorage.getItem('name')}
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider width="240" collapsed={sideClose}>
            <LeftMenu defaultOpenKeys={defaultOpenkeys} defaultSelectedKeys={defaultSelectKeys} />
            <div className="side-menu-control">
              <MenuOutlined className={sideClose ? 'anticon-close' : ''} onClick={handles.sideMenuSwitch} />
            </div>
          </Sider>
          <Content>
            <BreadCrumb />
            <div className="main-container">
              {props.children}
            </div>
            <Footer />
          </Content>
        </Layout>
        {/* 修改密码弹框 */}
        <ResetPassWord isModalVisible={mEditPass} setisModalVisible={setMEditPass} mode={2} />
      </Layout>
    </div>
  );
});
